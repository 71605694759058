import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="store"
export default class extends Controller {
  connect() {
    $('.modal').css('cssText','z-index: 1051 !important')
    subStoreValidation()
    storeValidation()
    hideSweetPayInstallmentPart()
    prequalLink()
    applySelect2()
    getLenderOptions($('#store_program_type_id'))
     $('#store_phone_number').keyup(function(){
      addDashes($(this))
    })
    ucfsCredentialDisplay()
    hideAndShowServicingFee()
    $('.new_store .new_store_name').keyup(function(){
      var name = $(this).val()
      $('.new_store .new_member_display_name').val(name)
    });

    $('#store_vive_password, #store_vive_status_password').on('keypress', function(event) {
      if (event.which === 32) {
        event.preventDefault(); // Prevent the space from being entered
        $('#store_vive_password-error').css('display', 'block')     
        $('#store_vive_password-error').text('Spaces are not allowed'); // Show error message
        } else {
          $('#store_vive_password-error').text(''); // Clear error message if any other key is pressed
        }
    });

    $('#store_vive_password, #store_vive_status_password').on('input', function() {
      var inputValue = $(this).val();

      var valueWithoutSpaces = inputValue.replace(/\s/g, '');

      $(this).val(valueWithoutSpaces);
    });
  }

  refreshToken(event){
    var element  = event.currentTarget
    var store_id =  $(element).data('store-id')
    var store_token = $(element).data('access-token')

    if (store_token != ''){
      var r = confirm("Do, you want to refresh token?");
    }else{
      r = true;
    }
    if (r == true) {
     // var store_id = #{store.id}
      $.ajax({
      type: "put",
      url: "/admin/members/"+store_id+"/generate_access_token",
      data: {},
      contentType: "application/json",
      dataType: "json",
      success: function(res){
        $("#access_token").text(res['access_token']);
      }
      });
    }
    }

  allMemberSelect(event){
    if($('#member_all:checked').length == 1){
      $('#store_member_association').select2('destroy').find('option').prop('selected', 'selected').end().select2();
    }
    else{
      $('#store_member_association').select2('destroy').find('option').prop('selected', false).end().select2();
    }
  }

  showingSweetPayInstallmentPart(event){

    if($('#store_in_house_finance').is(':checked')){
     $('.igw-field').css('display','block');
    }
    else{
     $('.igw-field').css('display','none');
    }

    if($('#store_in_house_finance').is(':unchecked')){
      $('.igw-field').css('display','none');
    }
  }
  copyText(){
    var text = $("#personal_prequal_url").text();
    var input = document.createElement('input');
    input.setAttribute('value', text);
    document.body.appendChild(input);
    input.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(input.value);
    document.body.removeChild(input);
    $('.copy_message').text('Copied');
    setTimeout(function() {
      $('.copy_message').text('');
    }, 2000);
  }
  prequalLink(){
    var prescreen = $('#store_prescreen').val();
    if (prescreen == 'true') {
      $('#prequal_link').show();
      $('.external_prime_lender_row').show()
      $('.prequal_element').show()
    }else{
      $('#prequal_link').hide();
      $('.external_prime_lender_row').hide()
      $('.external_prime_lender_row input').prop('checked', false)
      $('.prequal_element').hide()
      $('.prequal_element_checkbox input').prop('checked', false)
    }
  }

  externalPrimeCheckBox(event){
    var element  = event.currentTarget;
    console.log($(element).is(':checked'))
    if($(element).is(':checked')){
      $('.externalPrimeSelectOption').select2('val', ['1st'])
    }
    else{
      $('.externalPrimeSelectOption').val('').trigger('change')
    }

  }
  softPullError(){
    $('#store_tu_mode').valid()
    $('#store_prescreen').valid()
  }
  affPrequalChange(){
    var prequelCall = $('#store_prequel_call').val()
    affTokenDisplay(prequelCall == 'true')
  }

  getLenderOptions(event){
    var element  = event.currentTarget;
    var program_type_id = element.value;
    var program_type = $('#store_program_type_id option[value="'+ program_type_id +'"]').text()
    var store_id     = $('#s_id').val();

    if(store_id != 0 || program_type.length != '') {
      $.ajax({
        type: "get",
        url: "/admin/members/lenders_for_store?id=" + store_id,
        data: { "program_type" : program_type },
        success: function(res){
        }
      });
    }
  }

  setIlpToken(){
    var lenders     = $('#prequal_ids').val().split(',')
    var ilp         = false
    $('.lenderCheckBox:checked').each(function(index, item){
      if( lenders.includes($(item).attr('value')) && !ilp ){
        console.log('call setIlpToken')
        
        ilp = true
      }
    })
    affTokenDisplay(ilp)
    affPrequalDisplay(ilp)
  }

  setUcfsCredentialDisplay(){
    ucfsCredentialDisplay()
  }

  setUownMerchantNumberDisplay(){
    uownMerchantNumberDisplay()
  }

  setUpgradeHIMerchantNumberDisplay(event){
    var element = event.currentTarget
    var value = element.value

    if($('#upgrade_hi_lender_id').length !=0 ){
      var lender = $('#upgrade_hi_lender_id').val().split(',')
    }

    if(element.checked && lender == value){
      var hiLoanPurpose = {
        hi_remodel: 'HI Remodel',
        hi_replacement: 'HI Replacement', 
        hi_trades: 'HI Trades', 
        hi_detached_structures: 'HI Detached Structures',
        hi_yard: 'HI Yard'
      }

      const hiSpecificLoanPurpose = Object.entries(hiLoanPurpose).map(([key, value]) => {
        return { value: key, text: value };
      });
      
      var exist_loan_purpose = $('#store_loan_purpose').data('exist-loan-purpose')
      var dropdown = $('#store_loan_purpose');

      dropdown.empty(); // Clear existing options
      
      dropdown.append(`<option value=""> </option>`); // Add default option
    
      // Loop through the provided options and add them to the dropdown
      hiSpecificLoanPurpose.forEach(function(option) {
        dropdown.append($('<option>', {
            value: option.value,
            text: option.text
        }));   
      });
      if(exist_loan_purpose != '' && hiLoanPurpose[`${exist_loan_purpose}`] != ''){
        dropdown.val(`${exist_loan_purpose}`);
      }
    }else{
      if(lender == value){
        var allLoanPurpose = {
          audio_visual: "Audio/Visual",
          debt_consolidation: "Debt Consolidation",
          dental: "Dental",
          education: "Education",
          hi_detached_structures: "HI Detached Structures",
          hi_remodel: "HI Remodel",
          hi_replacement: "HI Replacement",
          hi_trades: "HI Trades",
          hi_yard: "HI Yard",
          home_furnishings: "Home Furnishings",
          home_improvement: "Home Improvement",
          jewelry: "Jewelry",
          medical: "Medical",
          non_auto_vehicle: "Non-Auto Vehicle",
          other: "Other",
          pets: "Pets",
          professional_services: "Professional Services",
          sporting_goods: "Sporting Goods",
          vehicle: "Vehicle"
        }
        const loanPurposedropdownOptions = Object.entries(allLoanPurpose).map(([key, value]) => {
          return { value: key, text: value };
        });
        
        var exist_loan_purpose = $('#store_loan_purpose').data('exist-loan-purpose')
        var dropdown = $('#store_loan_purpose');

        dropdown.empty(); // Clear existing options
        
        dropdown.append(`<option value=""> </option>`); // Add default option


        loanPurposedropdownOptions.forEach(function(option) {
          dropdown.append($('<option>', {
              value: option.value,
              text: option.text
          }));
        });

        if(exist_loan_purpose != '' && allLoanPurpose[`${exist_loan_purpose}`] != ''){
          dropdown.val(`${exist_loan_purpose}`);
        }
      }
    }
    upgradeHIMerchantNumberDisplay()
  }

  setViveMerchantNumberDisplay(){
    viveMerchantNumberDisplay()
  }

  memberDownPayment(){
    let minDownPayment;
    let targetDownPayment;

    minDownPayment    = $('#store_igw_config_minimum_down_payment').val();
    targetDownPayment = $('#store_igw_config_target_down_payment').val()

    if(minDownPayment == ''){
      minDownPayment = 0
    }else if (parseInt(minDownPayment) > 50) {
      minDownPayment = 50
    }

    if(targetDownPayment == ''){
      targetDownPayment = 80
    }else if (parseInt(targetDownPayment) < 0) {
      targetDownPayment = 0
    }

    $('#store_igw_config_target_down_payment').attr('min', minDownPayment)
    $('#store_igw_config_minimum_down_payment').attr('max', targetDownPayment)
    $('#store_igw_config_target_down_payment').prop('title', 'Target down payment must be between ' +  minDownPayment + '% and 80%.');
    $('#store_igw_config_minimum_down_payment').prop('title', 'Minimum down payment must be between 0% and '+ targetDownPayment +' %.');
    }

  settwoDecimalPoint(event){
    var element = event.currentTarget
    var value = element.value

    var num = parseFloat(value).toFixed(2)

    if (String(num) == 'NaN'){
      num = 0.00
    }
    $(element).val(num);
  }

  hideAndShowServicingFee(event){
    var element = event.currentTarget
    var select_option = $(element).find('option[value="' + element.value + '"]').text()
    if(select_option == 'SweetPay'){
     $('#store_servicing_fee').parent().parent().show()
    }else{
     $('#store_servicing_fee').parent().parent().hide()
    }
  }
}

window.ucfsCredentialDisplay = function(){
  var credentials = false
  if($('#ucfs_lender_id').length !=0 ){
    var lender = $('#ucfs_lender_id').val().split(',')
  }

  $('.lenderCheckBox:checked').each(function(index, item){
    if( lender.includes($(item).attr('value')) && !credentials ){
      credentials = true
    }
  })

  if (credentials) {
    $('#store_ucfs_user_id').parent().parent().show()
    $('#store_ucfs_password').parent().parent().show()
  }else{
    $('#store_ucfs_user_id').parent().parent().hide()
    $('#store_ucfs_password').parent().parent().hide()
  }

}

window.hideAndShowServicingFee = function(){
  var select_option = $('#store_dropbox_upload option:selected').text();
  if(select_option == 'SweetPay'){
   $('#store_servicing_fee').parent().parent().show()
  }else{
   $('#store_servicing_fee').parent().parent().hide()
  }
}

window.uownMerchantNumberDisplay = function() {
  if($('#uown_id').length !=0 ){
    var lender = $('#uown_id').val().split(',')
  }

  var uown_merchant = false
  $('.lenderCheckBox:checked').each(function(index, item){
    if(lender.includes($(item).attr('value')) && !uown_merchant ){
      uown_merchant = true
    }
  })
  if(uown_merchant){
    $('#store_member_number').parent().parent().show()
  }else{
    $('#store_member_number').parent().parent().hide()
  }
}

window.addAndRemoveLoanPurpose = function(){
  if($('#upgrade_hi_lender_id').length !=0 ){
    var lender = $('#upgrade_hi_lender_id').val().split(',')
  }
  
  var upgrade_hi_merchant = false
  var value = ''

  $('.lenderCheckBox:checked').each(function(index, item){
    if(lender.includes($(item).attr('value')) && !upgrade_hi_merchant ){
      upgrade_hi_merchant = true
    }
  })

  if(upgrade_hi_merchant){
    var hiLoanPurpose = {
      hi_remodel: 'HI Remodel',
      hi_replacement: 'HI Replacement',
      hi_trades: 'HI Trades', 
      hi_detached_structures: 'HI Detached Structures',
      hi_yard: 'HI Yard'
    }

    const hiSpecificLoanPurpose = Object.entries(hiLoanPurpose).map(([key, value]) => {
      return { value: key, text: value };
    });
    
    var exist_loan_purpose = $('#store_loan_purpose').data('exist-loan-purpose')
    var dropdown = $('#store_loan_purpose');

    dropdown.empty(); // Clear existing options
    
    dropdown.append(`<option value=""> </option>`); // Add default option
  
    // Loop through the provided options and add them to the dropdown
    hiSpecificLoanPurpose.forEach(function(option) {
      dropdown.append($('<option>', {
          value: option.value,
          text: option.text
      }));   
    });
    if(exist_loan_purpose != '' && hiLoanPurpose[`${exist_loan_purpose}`] != ''){
      dropdown.val(`${exist_loan_purpose}`);
    }
  }
}

window.upgradeHIMerchantNumberDisplay = function() {

  if($('#upgrade_hi_lender_id').length !=0 ){
    var lender = $('#upgrade_hi_lender_id').val().split(',')
  }
  
  var upgrade_hi_merchant = false

  $('.lenderCheckBox:checked').each(function(index, item){
    if(lender.includes($(item).attr('value')) && !upgrade_hi_merchant ){
      upgrade_hi_merchant = true
    }
  })

  if(upgrade_hi_merchant){
    $('#store_upgrade_merchant_id').parent().parent().show()
    $('#store_upgrade_initial_payment').parent().parent().show()
  }else{
    $('#store_upgrade_merchant_id').parent().parent().hide()
    $('#store_upgrade_initial_payment').parent().parent().hide()
  }
}

window.viveMerchantNumberDisplay = function(){
  if($('#vive_id').length !=0 ){
    var lender = $('#vive_id').val().split(',')
  }
  var vive_merchant = false

  $('.lenderCheckBox:checked').each(function(index, item){
    if(lender.includes($(item).attr('value')) && !vive_merchant ){
      vive_merchant = true
    }
  })

  if(vive_merchant){
    $('#store_vive_merchant_number').parent().parent().show()
    $('#store_vive_promo_code').parent().parent().show()
    $('#store_vive_apr').parent().parent().show()
    $('#store_vive_username').parent().parent().show()
    $('#store_vive_password').parent().parent().show()
    $('#store_vive_status_password').parent().parent().show()
  }else{
    $('#store_vive_merchant_number').parent().parent().hide()
    $('#store_vive_promo_code').parent().parent().hide()
    $('#store_vive_apr').parent().parent().hide()
    $('#store_vive_username').parent().parent().hide()
    $('#store_vive_password').parent().parent().hide()
    $('#store_vive_status_password').parent().parent().hide()
  }
}

window.ilpToken = function(){
  if($('#prequal_ids').length !=0 ){
    var lenders     = $('#prequal_ids').val().split(',')
  }
  var ilp         = false
  $('.lenderCheckBox:checked').each(function(index, item){
    if( lenders.includes($(item).attr('value')) && !ilp ){
      ilp = true
    }
  })
  affTokenDisplay(ilp)
  affPrequalDisplay(ilp)
}

window.affTokenDisplay = function(ilp){
  console.log('affTokenDisplay calling')
  if($('#aff_dtc_id').length !=0 ){
    var lenders = $('#aff_dtc_id').val().split(',')
  }
  var dtc = false
  var aff_finwise = false
  $('.lenderCheckBox:checked').each(function(index, item){
    if( lenders.includes($(item).attr('value')) && !dtc ){
      dtc = true
    }
  });

  var prequelCall = $('#store_prequel_call').val()
  if(ilp)
  ilp = (prequelCall == 'true')

  if($('#ilp_lender_ids').length !=0 ){
    lenders     = $('#ilp_lender_ids').val().split(',')
    aff_finwise_id = $('#aff_finwise_id').val()
  }

  $('.lenderCheckBox:checked').each(function(index, item){
    if( lenders.includes($(item).attr('value')) && !ilp ){
      ilp = true
    }
    if(aff_finwise_id == $(item).attr('value') && !aff_finwise){
      aff_finwise = true
    } 
  });


  if(ilp){
    if(!dtc){
      $('#store_ilp_token').val($('#ilp_token_value').val())
      $('#store_ilp_token').prop('required', true);
      $('#store_ilp_token').prop('disabled', false);
      $('#store_ilp_token').prop('')
      $('#store_ilp_token').parent().parent().show()
      $('#store_aff_dealer_id').parent().parent().show()
      $('.store_ilp_token .red-star').show()
    }else{
      $('#store_ilp_token').val('')
      $('#store_ilp_token').parent().parent().hide()
      $('#store_aff_dealer_id').parent().parent().hide()
      $('#store_ilp_token').prop('required', false);
      $('#store_ilp_token').prop('disabled', true);
      $('.store_ilp_token .red-star').hide()
    }

  }else{
    $('#store_ilp_token').val('')
    $('#store_ilp_token').parent().parent().hide()
    $('#store_aff_dealer_id').parent().parent().hide()
    $('#store_ilp_token').prop('required', false);
    $('#store_ilp_token').prop('disabled', true);
    $('.store_ilp_token .red-star').hide()
  }

  if(ilp && aff_finwise){
    if(!dtc){
      $('#store_aff_term_condition_token').val($('#aff_term_condition_token').val())
      $('#store_aff_term_condition_token').prop('required', true);
      $('#store_aff_term_condition_token').prop('disabled', false);
      $('#store_aff_term_condition_token').prop('');
      $('#store_aff_term_condition_token').parent().parent().show();
      $('#store_term_condition_token .red-star').show()
    }else{
      $('#store_aff_term_condition_token').val('')
      $('#store_aff_term_condition_token').parent().parent().hide();
      $('#store_aff_term_condition_token').prop('required', false);
      $('#store_aff_term_condition_token').prop('disabled', true);
      $('#store_term_condition_token .red-star').hide()
    }
  }else{
    $('#store_aff_term_condition_token').val('')
    $('#store_aff_term_condition_token').parent().parent().hide();
    $('#store_aff_term_condition_token').prop('required', false);
    $('#store_aff_term_condition_token').prop('disabled', true);
    $('.store_term_condition_token .red-star').hide()
  }
}

window.affPrequalDisplay = function(value){
  if($('#prequal_ids').length !=0 ){
    var prequalIds = $('#prequal_ids').val().split(',')
  }
  var ilp         = false
  $('.lenderCheckBox:checked').each(function(index, item){
    if( prequalIds.includes($(item).attr('value')) && !ilp ){
      ilp = true
    }
  })
  if(ilp){
    $('#store_prequel_call').parent().parent().show()
  }else{
    $('#store_prequel_call').parent().parent().hide()
  }
}

window.getLenderOptions = function (element){
  var program_type_id = element.val();
  var program_type = $('#store_program_type_id option[value="'+ program_type_id +'"]').text()
  var store_id     = $('#s_id').val();

  if(store_id != undefined && program_type.length != '' ) {
    $.ajax({
      type: "get",
      url: "/admin/members/lenders_for_store?id=" + store_id,
      data: { "program_type" : program_type },
      success: function(res){
      }
    });
  }
}

window.prequalLink = function(){
  var prescreen = $('#store_prescreen').val();
  if (prescreen == 'true') {
    $('#prequal_link').show();
    $('.external_prime_lender_row').show()
    $('.prequal_element').show()
  }else{
    $('#prequal_link').hide();
    $('.external_prime_lender_row').hide()
    $('.external_prime_lender_row input').prop('checked', false)
    $('.prequal_element').hide()
    $('.prequal_element_checkbox input').prop('checked', false)
  }
}

window.subStoreValidation = function(){
   if($('#sub_store_id_form').length == 1){
    $('#sub_store_id_form').validate({
      rules: {
         'sub_stores_number':{
            required: true,
            min: 1,
            max: 500
         }
       },
       messages: {
         'sub_stores_number':{
            required: 'Please enter number of sublinks to be generated.',
            min: 'Please enter number greater then 0.',
            max: 'Please enter the number of Sublink between 1 to 500.'
         }
       }
     });
   }
  $('#sub_store_id_form').submit(function(){
    if($('#sub_store_id_form').valid()){
      $('#sub_store_id_form .submission').attr('disabled', true)
      $('#sub_store_id_form .submission').val('Generating...')
    }
  })
  if($('#import_sub_store_form').length == 1 ){
    $('#import_sub_store_form').validate({
      rules: {
        'file': {
          required: true,
        }
      },
      messages: {
        'file': {
         required: 'Please select CSV file to import.'
        }
      }
   });
  }
  $('#import_sub_store_form').submit(function(){
    if($('#import_sub_store_form').valid()){
      $('#import_sub_store_form .submission').attr('disabled', true)
      $('#import_sub_store_form .submission').val('Importing...')
    }
  })
}

window.storeValidation = function() {
  $("#store_id").validate({
    rules: {
      "store[name]": {
        required: true,
        minlength: 2,
        maxlength: 100,
        blankSpace: true
      },
      "store[program_type_id]": {
        required: true,
      },
      "store[email]": {
        required: true,
        emailregex: true
      },
      "store[phone_number]": {
        required: false,
        storephoneregex: true,
        minlength: 12,
        maxlength: 12
      },
      "store[member_number]":{
        required: true
      },
      "store[aff_dealer_id]": {
        required: false,
        storenameregex: true,
        minlength: 0,
        maxlength: 20
      },
      "store[advisor_firm_id]": {
        required: false,
        advisor_firm: true,
        minlength: 0,
        maxlength: 100
      },
      "store[active]": {
        required: true
      },
      "store[callback_url]": {
        required: false,
        callbackurlregex: true
      },
      "store[secondary_email][]": {
        secondaryemailregex: true
      },
      "store[tu_mode]":{
        validate_softpull: true
      },
      "store[prescreen]": {
        validate_prescreen: true
      }
    },
    messages: {
      "store[name]": {
        required: "The name is required.",
        minlength: "The name must be more than 2 and less than 100 characters long.",
        maxlength: "The name must be more than 2 and less than 100 characters long.",
        blankSpace: 'Blank space not allowed.'
      },
      "store[program_type_id]": {
        required: "Please select program type.",
      },
      "store[email]": {
        required: "The enter email address.",
        emailregex: "Please enter valid email address."
      },
      "store[phone_number]": {
        storephoneregex: "Phone number must be 10 numeric digits only.",
        minlength: "The phone no.should be 10 digits.",
        maxlength: "The phone no.should be 10 digits."
      },
      "store[member_number]":{
        required: 'Merchant number is required.',
      },
      "store[aff_dealer_id]": {
        storenameregex: "AFF Dealer ID cannot contain special characters.",
        minlength: "AFF Dealer ID must be less than 20 characters long.",
        maxlength: "AFF Dealer ID must be less than 20 characters long."
      },
      "store[advisor_firm_id]": {
        advisor_firm: "Advisor Firm ID cannot contain special characters.",
        minlength: "Advisor Firm ID must be less than 100 characters long.",
        maxlength: "Advisor Firm ID be less than 100 characters long."
      },
      "store[active]": {
        required: "The active is required."
      },
      "store[callback_url]": {
        callbackurlregex: "Invalid input for URL."
      },
      "store[secondary_email][]": {
        secondaryemailregex: "Please enter valid email address."
      },
      "store[tu_mode]":{
        validate_softpull: "Either of Soft Pull or Prequal can be active."
      },
      "store[prescreen]": {
        validate_prescreen: "Either of Soft Pull or Prequal can be active."
      }
    },
  });
    $.validator.addMethod("blankSpace", function(value, element) {
     if(value.replaceAll(' ', '').length == 0){
        return false
      }else{
        return this.optional(element) || true
      }
    });

    $.validator.addMethod("emailregex", function(value, element) {
      return this.optional(element) || /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b$/i.test(value);
    });
    $.validator.addMethod("storenameregex", function(value, element) {
      return this.optional(element) || /^[a-zA-Z0-9\s\r\n\?]+$/.test(value);
    });
    $.validator.addMethod("advisor_firm", function(value, element) {
      return this.optional(element) || /^[a-zA-Z0-9_-]+$/.test(value);
    });
    $.validator.addMethod("storephoneregex", function(value, element) {
      return this.optional(element) || /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(value);
    });
    $.validator.addMethod("callbackurlregex", function(value, element){
      return this.optional(element) || /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/.test(value);
    });
    $.validator.addMethod("nameValidation", function(value, element) {
      return this.optional(element) || /^([a-zA-Z'-]+\s)*[a-zA-Z'-]+$/.test(value);
    });
    $.validator.addMethod("secondaryemailregex", function(value, element) {
      let y = true
      for(let x of value) {
        y = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b$/i.test(x);
        if (y === false) {
          y = false
        }
      }
      return this.optional(element) || y
    });

    $.validator.addMethod("validate_softpull", function(value, element) {
      var select_prescreen = $('#store_prescreen').val()
      if(value == 'inactive'){
        return true
      }
      else{
        return this.optional(element) || select_prescreen == "false"
      }
    });

    $.validator.addMethod("validate_prescreen", function(value, element) {
      var select_prescreen = $('#store_tu_mode').val()
      if(value == 'false'){
        return true
      }
      else{
        return this.optional(element) || select_prescreen == "inactive"
      }
    });

      $('#store_phone_number').keyup(function(){
        addDashes($(this))
      })
}

window.applySelect2 = function(){
  $('#store_lender_ids, #member_ids, #store_member_association, .lenderTierSection, #store_payment_plan_frequency').select2({
    placeholder: "Please Select",
    multipart: true,
  });
  
  $("#store_secondary_email").select2({
    tags: true,
    tokenSeparators: [',', ' ']
  })

  $('.lenderTierSection').select2({
    placeholder: "Please Select",
    multipart: true
  });
}

window.hideSweetPayInstallmentPart = function(){
  if($('#store_in_house_finance').is(':unchecked')){
    $('.igw-field').css('display','none');
  }
}
