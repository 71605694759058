import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--spi-payment-statuses"
export default class extends Controller {
  connect() {
    console.log('connect spi status')
    $('.spi_payment_member_id').select2({
      minimumInputLength: 3,
      placeholder: "All",
      allowClear: true
      // multiple: true
    });

    $('#account_status').select2({
      placeholder: "All",
      allowClear: true
    });
      
    

    $('#loading_data').hide()

    $('.right-arrow').click(function(){
      $('.spi_report_table').animate( { scrollLeft: '+=100' }, 100);
    })
    $('.left-arrow').click(function(){
      $('.spi_report_table').animate( { scrollLeft: '-=100' }, 100);
    })

    if($('#payment_status_filter_worked').is(':checked')){
      $('#payment_status').parent().parent().parent().show()
      $('input[type="checkbox"]').parent().parent().parent().parent().show()
      $('#member_id').parent().parent().parent().show()
      $('#payment_date').parent().parent().parent().show()
      $('#account_status').parent().parent().parent().show()
      $('#account_status').val(['active', 'delinquent']).trigger('change')
    }
    
    
    if($('#payment_status_table_collection_section').length == 1){
      $.fn.dataTable.ext.errMode = 'none';
      $('#payment_status_table_collection_section').DataTable({
        paging: false,        // Disable pagination
        ordering: false,      // Disable sorting
        info: false,          // Disable table information display
        searching: true       // Enable search functionality
      });
      $('#payment_status_table_collection_section_filter').detach().prependTo('#searchContainer');
      $('#payment_status_table_collection_section_filter label').css('display', 'inline-flex');
      $('#payment_status_table_collection_section_filter input').css('margin-left', '10px');
    }

  }

  collectionChecked(e){
    var element = event.currentTarget
    if(element.checked){
      $('#member_id').parent().parent().parent().show()
      $('input[type="checkbox"]').parent().parent().parent().parent().hide()
      $('#payment_status').parent().parent().parent().hide()
      $('#payment_date').parent().parent().parent().hide()
      $('#account_status').parent().parent().parent().hide()
      $('#account_status').empty()
      $("#spi_payment_statuses_report").empty(); 
    }
  }

  inprocessChecked(e){
    $('#payment_status').parent().parent().parent().show()
    $('input[type="checkbox"]').parent().parent().parent().parent().show()
    $('#member_id').parent().parent().parent().show()
    $('#payment_date').parent().parent().parent().show()
    $('#account_status').parent().parent().parent().show()
    if ($('#account_status option').length === 0) {
      // Append multiple options if none exist
      $('#account_status').append(`
          <option value="active">Active</option>
          <option value="bankruptcy">Bankruptcy</option>
          <option value="delinquent">Delinquent</option>
          <option value="dunning_1">Dunning 1</option>
          <option value="dunning_2">Dunning 2</option>
          <option value="paused">Paused</option>
      `);

      // Update Select2 if applied
      $('#account_status').trigger('change');
    }
    $('#account_status').val(['active', 'delinquent']).trigger('change')
    $("#spi_payment_statuses_report").empty();
  }

  clearedChecked(e){
    console.log('clicked')
    var element = event.currentTarget
    if(element.checked){
      $('input[type="checkbox"]').parent().parent().parent().parent().show()
      $('#member_id').parent().parent().parent().show()
      $('#payment_status').parent().parent().parent().hide()
      $('#payment_date').parent().parent().parent().hide()
      $('#account_status').parent().parent().parent().hide()
      $('#account_status').empty()
      $("#spi_payment_statuses_report").empty();

    }
  }

  submitForm(){
    if($('#spi-payment-statuses').valid())
      $('#loading_data').show()
  }
}
